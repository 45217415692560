import React from "react";

import {withSEO} from "src/hoc/SEO";
import { pages } from "src/config";

import {LogoRow, PanaramicRow, WelcomeRow, KeyServicesRow} from "src/components/pages/Home";

const HomePage = () => {
  return (
    <main>
      <LogoRow page={pages.home}/>
      <PanaramicRow/>
      <WelcomeRow page={pages.home}/>
      <KeyServicesRow page={pages.home}/>
    </main>
  );
};

export default withSEO({
  title: pages.home.title
})(HomePage);
