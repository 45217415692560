import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import CVRTLogo from "src/containers/CVRTLogo";
import Column from "src/components/Column";

import css from "./index.module.scss";


const LogoRow = ({ page: { config: { logoRow }}}) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          logo: file(relativePath: { eq: "images/test-centre/logo.png" }) {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <Column className={css.logoRow} direction="cc">
          <Column className={css.logoColumn} direction="cc">
            <Img className={css.logoImg} fluid={data.logo.childImageSharp.fluid}/>
          </Column>
          <CVRTLogo hidePhoneNumber={true}/>
        </Column>
      )}
    />
  );
};

LogoRow.propTypes = {
  page: PropTypes.object.isRequired
};

export default LogoRow;