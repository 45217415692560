import React from "react";
import PropTypes from "prop-types";

import Column from "src/components/Column";
import Header from "src/components/Header";

import css from "./index.module.scss";

const KeyServicesRow = ({ page: { config: { keyServicesRow }}}) => {
  
  const renderServices = (services, servicesPerRow) => {
    const rows = [];
    let rowElements = [];
    const serviceKeys = Object.keys(services).sort((a, b) => services[a].order - services[b].order);
    serviceKeys.forEach((key, index) => {
      if(index > 0 && index % servicesPerRow === 0) {
        rows.push(<tr key={index}>{rowElements}</tr>);
        rowElements = [];
      }
      const service = services[key];
      rowElements.push(
        <td key={key}>
          {renderComponent(service.icon.component)}
          <h3>{service.icon.text}</h3>
          <p>{service.text}</p>
        </td>
      );
    });
    rows.push(<tr key={serviceKeys.length}>{rowElements}</tr>);
    rowElements = [];
    return rows;
  };
  
  const renderComponent = Component => {
    return <Component className={css.serviceIcon}/>;
  };
  
  return (
    <Column className={css.keyServicesRow} direction="cc">
      <Header primaryText={keyServicesRow.headingPrimary} secondaryText={keyServicesRow.headingSecondary}/>
      <table>
        <tbody>
        {renderServices(keyServicesRow.services, keyServicesRow.servicesPerRow)}
        </tbody>
      </table>
    </Column>
  );
};

KeyServicesRow.propTypes = {
  page: PropTypes.object.isRequired
};

export default KeyServicesRow;