import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image"

import Row from "src/components/Row";

import css from "./index.module.scss";


const PanaramicRow = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          panaramic: file(relativePath: { eq: "images/test-centre/panaramic.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <Row className={css.panaramicRow} direction="cc">
          <Img className={css.panaramicImg} fluid={data.panaramic.childImageSharp.fluid}/>
        </Row>
      )}
    />
  );
};

export default PanaramicRow;