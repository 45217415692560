import React from "react";
import PropTypes from "prop-types";

import Column from "src/components/Column";
import Header from "src/components/Header";

import css from "./index.module.scss";


const WelcomeRow = ({ page: { config: { welcomeRow }}}) => {
  return (
    <Column className={css.welcomeRow} direction="cc">
      <Header primaryText={welcomeRow.headingPrimary} secondaryText={welcomeRow.headingSecondary}/>
      <p>
        {welcomeRow.text}
      </p>
    </Column>
  );
};

WelcomeRow.propTypes = {
  page: PropTypes.object.isRequired
};

export default WelcomeRow;
